.cr-word-cloud {
  font-family: 'Roboto', sans-serif;
  text-align: center;

  .tag-cloud-tag {
    font-weight: 500;
    padding: 4px;
    text-align: center;
    line-height: 1;
  }
}
