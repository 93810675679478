.loader {
  -webkit-animation: spin 3s infinite linear; /* Safari */
  animation: spin 3s infinite linear;
}

/* Safari */
@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
