.deal-card {
  height: 170px;
  background: #fdfdfd;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .deal-card-header {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    cursor: pointer;

    .deal-card-name {
      font-size: 18px;
      font-weight: 400;
      white-space: nowrap;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deal-card-contact-email {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }

    .deal-card-menu {
      position: absolute;
      top: -10px;
      right: -8px;

      .deal-card-menu-button {
        padding: 0;
        min-width: 40px;
        min-height: inherit;
        max-height: 25px;
        border-radius: 0px;
      }
    }
  }

  .deal-card-separator {
    width: 44px;
    height: 0px;
    border: 1px solid #c9c9c9;
    margin: 8px 0px;
  }

  .deal-card-body {
    .deal-card-body-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deal-card-body-status {
      height: 26px;
      padding: 0px 10px;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .deal-card-body-status-pending {
      background: #e59a05;
    }

    .deal-card-body-status-in-progress {
      background: #02a05c;
    }

    .deal-card-body-status-closed {
      background: #898989;
    }

    .deal-card-body-status-danger p {
      font-size: 11px !important;
    }

    .deal-card-body-status-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: #ffffff;
      text-transform: capitalize;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deal-card-body-day-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deal-card-body-day-text-pending {
      color: #e59a05;
    }

    .deal-card-body-day-text-in-progress {
      color: #02a05c;
    }

    .deal-card-body-day-text-closed {
      color: #898989;
    }
  }
}
