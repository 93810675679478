.session-feedback {
  // .paper {
  //   margin-top: 30px;
  // }

  .cr-logo {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.15px !important;
    // position: absolute;
    color: #898989;
  }

  .header {
    // padding: 15px;

    // .cr-logo {
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   letter-spacing: 0.15px !important;
    //   position: absolute;
    //   color: #898989;
    // }

    // h5 {
    //   font-size: 1rem;
    //   text-align: center;
    // }
  }

  .form-header {
    font-weight: 300;
    font-size: 30px;
    padding-bottom: 72px;
    max-width: 75%;
    margin: 0 auto;
    text-align: center;
  }

  // .body {
  //   padding: 15px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;

  // }

  form {
    width: 100%;
    max-width: 600px;
  }
}
