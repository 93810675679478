@import '../../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.sentiment-field {
  margin-bottom: 100px;

  .sentiment-option {
    width: 200px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

    @include media-breakpoint-down('sm') {
      padding: 20px;
      flex: 1;
    }

    @include media-breakpoint-down('xs') {
      height: initial;
      width: initial;
    }
  }
}
