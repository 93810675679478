.project-card-body-status {
  height: 26px;
  padding: 0px 10px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-status-pill {
  // min-width: 300px;
}

.project-card-body-status-pending {
  background: #e59a05;
}

.project-card-body-status-in-progress {
  background: #02a05c;
}

.project-card-body-status-closed {
  background: #898989;
}

.project-card-body-status-text {
  font-weight: 500;
  font-size: 13px !important;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-transform: capitalize;
}

.project-card-body-status-danger p {
  font-size: 11px !important;
}
