@import '../../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.page-content-paper {
  margin: 0 auto;
  width: 100%;
  min-height: 270px;
  margin-bottom: 30px;

  .page-content-header {
    padding: 20px;
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    &.with-logo {
      padding: 20px;
      margin-top: 30px;

      .left-col {
        top: 10px;

        @media screen and (max-width: 768px) {
          top: 0;
          left: 0;
          margin-bottom: 15px;
        }
      }
    }

    .left-col {
      position: absolute;
      left: 20px;
      top: 25px;

      @media screen and (max-width: 768px) {
        position: relative;
        left: 0;
        top: 0;
        text-align: center;
        margin-bottom: 5px;
      }
    }

    .right-col {
      position: absolute;
      right: 15px;
      top: 15px;

      @media screen and (max-width: 768px) {
        position: relative;
        left: 0;
        text-align: center;
        margin-bottom: 5px;
      }
    }

    .page-content-title {
      font-size: 16px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      line-height: 35px;
    }
  }

  .page-content-body {
    padding: 0 30px 30px;

    /**
     * This is to keep buttons on the bottom of the particiapnt
     * view on small screens from getting hidden behind the
     * notification/timer area which is fix positioned at hte bottom
     */
    @include media-breakpoint-down('sm') {
      margin-bottom: 120px;
    }

    &.center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
