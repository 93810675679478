.slide {
  animation-duration: 0.3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 40%;
    width: 40%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
