.feedback-detail {
  .ct-chart {
    margin-bottom: 50px;

    .ct-chart-donut {
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));

      .ct-series {
        &.satisfied {
          stroke: #02a05c;
        }
        &.neutral {
          stroke: #fdc300;
        }
        &.dissatisfied {
          stroke: #dd380c;
        }
      }

      .ct-label {
        display: none;
      }
    }
  }

  .session-feedback-summary {
    margin-bottom: 75px;
  }
}
