@import '../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.video-container {
  position: fixed;
  width: calc(100% - 200px);
  height: calc(100% - 48px);
  left: 0;
  top: 48px;

  @include media-breakpoint-down('sm') {
    width: 100%;
    height: calc(100% - 148px);
  }
}
