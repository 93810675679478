.deal-card-body-status {
  height: 26px;
  padding: 0px 10px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deal-card-body-status-pending {
  background: #e59a05;
}

.deal-card-body-status-in-progress {
  background: #02a05c;
}

.deal-card-body-status-closed {
  background: #898989;
}

.deal-card-body-status-text {
  font-weight: 500;
  font-size: 13px !important;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-transform: capitalize;
}

.deal-card-body-day-text {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 21px;
  letter-spacing: 0.25px;
}

.deal-card-body-day-text-pending {
  color: #e59a05;
}

.deal-card-body-day-text-in-progress {
  color: #02a05c;
}

.deal-card-body-day-text-closed {
  color: #898989;
}

.deal-card-body-status-danger p {
  font-size: 11px !important;
}
