.hover-pointer {
  &:hover {
    cursor: pointer !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: visible !important;
    // -webkit-print-color-adjust: exact;
    background: white;
  }
}

@page {
  size: auto;
  margin: 0.5in;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported */
  hyphens: auto;
}

.pointer-underline-hover {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-pre {
  white-space: pre;
}

.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}
