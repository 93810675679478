@import '../../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.participant-timer {
  margin-top: 16px;

  @include media-breakpoint-down('sm') {
    margin-top: 0;
  }

  .value {
    font-weight: 500;
    font-size: 36px;

    @include media-breakpoint-down('sm') {
      font-size: 13px;
    }
  }
}
