@import '../../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.form-dialog {
  .form-dialog-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    @include media-breakpoint-down('xs') {
      flex-direction: column-reverse;
    }

    .MuiButtonBase-root {
      margin-bottom: 16px !important;
      margin-right: 8px !important;

      @include media-breakpoint-down('xs') {
        margin-right: initial !important;
      }
    }
  }
}
