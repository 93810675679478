@import '../../assets/scss/control-room/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.participant-sidebar {
  width: 200px;
  height: 100vh;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-down('sm') {
    width: 100vw;
    height: 100px;
    bottom: 0;
    left: 0;
    top: initial;
    z-index: 10;
  }

  .auth-user-button {
    &:hover {
      background: none;
    }
  }

  .broadcast-history {
    color: black;
    overflow-y: scroll;

    @include media-breakpoint-down('sm') {
      overflow: initial;
    }

    .broadcast-item {
      margin-bottom: 20px;

      .broadcast-time {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;

        @include media-breakpoint-down('sm') {
          color: rgba(0, 0, 0, 0.6);
          font-size: 13px;
          line-height: 12px;
          font-weight: 500;
        }
      }

      .broadcast-message {
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);

        @include media-breakpoint-down('sm') {
          color: #9e9e9e;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}
