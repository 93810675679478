.overlay-with-children {
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    background: rgba(0, 0, 0, 0.33);
    z-index: 1;
  }

  &.full-overlay {
    &::after {
      position: fixed;
      z-index: 999999999;
    }
  }
}

.overlay-with-children-children {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &.full-overlay {
    position: fixed;
    z-index: 9999999999;
  }
}
