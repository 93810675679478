// .text-area  {
//   margin-bottom: 0 !important;
// }

.participant-inactive {
  color: #e2351e;
}

hr.divider {
  border-top-color: lightgray;
}
