.score-field {
  margin-bottom: 100px;

  @media screen and (max-width: 767px) {
    margin-bottom: 48px;
  }

  .score-option {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding: 0 9px;
    margin: 12px;

    & .option-vote {
      font-size: 30px;
    }

    & .option-text {
      font-size: 15px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      height: 77px;
      width: 77px;
      margin: 8px;

      & .option-vote {
        font-size: 25px;
      }

      & .option-text {
        font-size: 12px;
      }
    }
  }
}
