.job-seat-card {
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
}

.lead-pill {
  margin-right: 5px;
  height: 22px;
  width: 45px;
  border-radius: 99px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.25px;
  }
}
