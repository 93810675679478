.linear-scale-field {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    margin-bottom: 48px;
  }

  .score-active {
    border: 3px solid #02a05c;
  }

  .score-option {
    width: 100px;
    height: 114px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: none;
    padding: 0 9px;
    margin: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    & .option-vote {
      font-size: 30px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      height: 85px;
      width: 75px;
      margin: 8px;

      & .option-vote {
        font-size: 25px;
      }
    }
  }

  .option-text {
    font-size: 14px;
    max-width: 100px;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      max-width: 75px;
    }
  }

  .arrow-svg {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 97%;

    @media screen and (max-width: 767px) {
      max-width: 455px;
    }

    @media screen and (max-width: 544px) {
      display: none;
    }
  }
}
