.text-area {
  margin-bottom: 1rem;
  font-weight: 400;
}

.transparent-text-area {
  background: transparent !important;
  background-color: transparent !important;

  &::before,
  &::after {
    border: 0 !important;
  }
}

.MuiFilledInput-root {
  background: rgba(0, 0, 0, 0.04) !important;
}
