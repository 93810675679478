.current-assignment-text {
  .ck-content {
    .image {
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;

      img {
        max-width: 100%;
      }
    }
  }
}

.ideaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
