.mural-canvas {
  min-height: 800px;
  min-width: 100%;
  width: 100%;
  border: 2px dashed lightgray;
}

.mural-error {
  min-height: 800px;
  min-width: 100%;
}
