.session-card {
  margin-bottom: 15px;
  height: 100%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
  overflow: initial !important;

  .card-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;

    .card-header-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .progress-container {
      flex: 1;
      padding: 10px 0 0 10px;
      cursor: pointer;
    }

    .session-card-menu {
      position: absolute;
      top: -10px;
      right: -8px;

      .session-card-menu-button {
        padding: 0;
        min-width: 40px;
        min-height: inherit;
        max-height: 25px;
      }
    }
  }

  .status-indicator {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    display: inline-block;

    &.empty {
      background: linear-gradient(248.96deg, #c4d3dd 14.2%, #bed0d6 86.05%);
    }

    &.satisfied {
      background: linear-gradient(248.96deg, #02a05c 14.2%, #019756 86.05%);
    }

    &.dissatisfied {
      background: linear-gradient(248.96deg, #f7543f 14.2%, #e2351e 86.05%);
    }

    &.neutral {
      background: linear-gradient(248.96deg, #efb521 14.2%, #daae12 86.05%);
    }
  }

  .card-sentiment {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card-action-area {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    text-align: inherit;
  }
}
