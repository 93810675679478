.checklistCard {
  box-shadow: '0px 4px 7px rgba(0, 0, 0, 0.1)';
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  padding: 0px 10px;

  .checklistRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0px;
    cursor: pointer;

    .checklistTitle {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .checklistCount {
      font-size: 15px;
      font-weight: 400;
      margin-left: 5px;
    }

    .completedChecklist {
      color: #02a05c;
    }

    .overdueLabel {
      color: #e2351e;
      align-items: center;
      justify-content: center;
    }
  }

  .checklistRowLast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    cursor: pointer;

    .checklistTitle {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .checklistCount {
      font-size: 15px;
      font-weight: 400;
      margin-left: 5px;
    }

    .completedChecklist {
      color: #02a05c;
    }

    .overdueLabel {
      color: #e2351e;
      align-items: center;
      justify-content: center;
    }
  }
}
