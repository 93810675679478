.create-session-card {
  max-width: 800px;
}

.session-item-media {
  width: 250px;
}

.session-item-name {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 5px 10px 10px;
}
