.contractor-card {
  margin-bottom: 15px;
  height: 100%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
  overflow: initial !important;
  border-radius: 4px;

  .card-header {
    display: flex;
    align-items: flex-start;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
    width: 100%;
    flex-direction: column;

    .name {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
    .contactInfo {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);

      p {
        margin: 0px;
      }
    }

    .contractor-card-menu {
      position: absolute;
      top: -10px;
      right: -8px;

      .contractor-card-menu-button {
        padding: 0;
        min-width: 40px;
        min-height: inherit;
        max-height: 25px;
        border-radius: 0px;
      }
    }
  }

  .divider {
    border-top: 1.5px solid #c9c9c9;
    width: 44px;
    margin: 10px 0px;
  }

  .pointer {
    cursor: pointer;
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #02a05c;
  }
  .status-inactive {
    color: rgba(0, 0, 0, 0.87);
  }
  .status-available {
    color: #e59a05 !important;
  }

  .notes {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.inactive-contractor-card {
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  background: #f2f2f2 !important;
}
