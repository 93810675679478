// Scales for responsive SVG containers
$ct-scales: (
  (1),
  (15/16),
  (8/9),
  (5/6),
  (4/5),
  (3/4),
  (2/3),
  (5/8),
  (1/1.618),
  (3/5),
  (9/16),
  (8/15),
  (1/2),
  (2/5),
  (3/8),
  (1/3),
  (1/4)
) !default;
$ct-scales-names: (
  ct-square,
  ct-minor-second,
  ct-major-second,
  ct-minor-third,
  ct-major-third,
  ct-perfect-fourth,
  ct-perfect-fifth,
  ct-minor-sixth,
  ct-golden-section,
  ct-major-sixth,
  ct-minor-seventh,
  ct-major-seventh,
  ct-octave,
  ct-major-tenth,
  ct-major-eleventh,
  ct-major-twelfth,
  ct-double-octave
) !default;

$ct-class-chart: ct-chart !default;
$ct-class-chart-line: ct-chart-line !default;
$ct-class-chart-bar: ct-chart-bar !default;
$ct-class-horizontal-bars: ct-horizontal-bars !default;
$ct-class-chart-pie: ct-chart-pie !default;
$ct-class-chart-donut: ct-chart-donut !default;
$ct-class-label: ct-label !default;
$ct-class-series: ct-series !default;
$ct-class-line: ct-line !default;
$ct-class-point: ct-point !default;
$ct-class-area: ct-area !default;
$ct-class-bar: ct-bar !default;
$ct-class-slice-pie: ct-slice-pie !default;
$ct-class-slice-donut: ct-slice-donut !default;
$ct-class-grid: ct-grid !default;
$ct-class-slice-donut-solid: ct-slice-donut-solid !default;
$ct-class-grid-background: ct-grid-background !default;
$ct-class-vertical: ct-vertical !default;
$ct-class-horizontal: ct-horizontal !default;
$ct-class-start: ct-start !default;
$ct-class-end: ct-end !default;

// Class names to be used when generating CSS

// Container ratio
$ct-container-ratio: (1/1.618) !default;

// Text styles for labels
$ct-text-color: rgba(0, 0, 0, 0.4) !default;
$ct-text-size: 1.3rem !default;
$ct-text-align: flex-start !default;
$ct-text-justify: flex-start !default;
$ct-text-line-height: 1;

// Grid styles
$ct-grid-color: rgba(0, 0, 0, 0.2) !default;
$ct-grid-dasharray: 2px !default;
$ct-grid-width: 1px !default;
$ct-grid-background-fill: none !default;

// Line chart properties
$ct-line-width: 4px !default;
$ct-line-dasharray: false !default;
$ct-point-size: 10px !default;

// Line chart point, can be either round or square
$ct-point-shape: round !default;
// Area fill transparency between 0 and 1
$ct-area-opacity: 0.1 !default;

// Bar chart bar width
$ct-bar-width: 10px !default;

// Donut width (If donut width is to big it can cause issues where the shape gets distorted)
$ct-donut-width: 60px !default;

// If set to true it will include the default classes and generate CSS output. If you're planning to use the mixins you
// should set this property to false
$ct-include-classes: true !default;

// If this is set to true the CSS will contain colored series. You can extend or change the color with the
// properties below
$ct-include-colored-series: $ct-include-classes !default;

// If set to true this will include all responsive container variations using the scales defined at the top of the script
$ct-include-alternative-responsive-containers: $ct-include-classes !default;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) !default;
$ct-series-colors: (
  $brand-info,
  $brand-danger,
  $brand-warning,
  $brand-primary,
  $brand-success,
  $font-background-light-grey,
  $gray-color,
  $social-google,
  $social-tumblr,
  $social-youtube,
  $social-twitter,
  $social-pinterest,
  $social-behance,
  #6188e2,
  #a748ca
) !default;

.ct-tester-graph {
  min-width: 200px;
  width: 100%;
  max-width: 420px;
  height: 35px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
}

.ct-tester-graph .ct-series-a .ct-bar {
  stroke: #bfe9d7 !important;
  stroke-width: 22px;
}

.ct-tester-graph .ct-series-b .ct-bar {
  stroke: #ffecae !important;
  stroke-width: 22px;
}

.ct-tester-graph .ct-series-c .ct-bar {
  stroke: #ffcbc5 !important;
  stroke-width: 22px;
}

.ct-tester-graph .ct-series-d .ct-bar {
  stroke: #d7d5d5 !important;
  stroke-width: 22px;
}

.ct-tester-graph .ct-legend {
  position: absolute;
  z-index: 10;
  bottom: 60px;
  right: -15px;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin: 0 !important;
  margin-left: auto !important;

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 3px;
    margin-left: 20px;
    list-style: none;
    font-weight: 500;
    font-size: 14px;
  }

  li:before {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2px;
    content: '';
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }

  li.inactive:before {
    background: transparent;
  }

  &.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ct-series-0:before {
    background-color: #bfe9d7;
  }

  .ct-series-1:before {
    background-color: #ffecae;
  }

  .ct-series-2:before {
    background-color: #ffcbc5;
  }

  .ct-series-3:before {
    background-color: #d7d5d5;
  }
}

@media (max-width: 991px) {
  .ct-tester-graph .ct-legend {
    li {
      margin-left: 5px;
    }
  }
}
