.deal-project-card {
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
}

.deal-project-header {
  cursor: pointer;
}

.project-card {
  margin-bottom: 15px;
  height: 100%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(104, 87, 87, 0.12) !important;
  overflow: initial !important;
  border-radius: 4px;

  .project-card-header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
    width: 100%;
    flex-direction: column;

    .project-card-event {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }

    .project-card-date {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
    }

    .project-card-header-names {
      height: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .project-card-header-content {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);

      p {
        margin: 0px;
      }
    }

    .project-card-menu {
      position: absolute;
      top: -10px;
      right: -8px;

      .project-card-menu-button {
        padding: 0;
        min-width: 40px;
        min-height: inherit;
        max-height: 25px;
        border-radius: 0px;
      }
    }
  }

  .project-card-bottom {
    display: flex;
    justify-content: center;
  }

  .pointer {
    cursor: pointer;
  }

  .info-header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px;
  }

  .info-content {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #02a05c;
  }
  .status-inactive {
    color: rgba(0, 0, 0, 0.87);
  }

  .notes {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }
}

// Inactive
.inactive-project-card {
  margin-bottom: 15px;
  height: 100%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.12) !important;
  overflow: initial !important;
  border-radius: 4px;
  background: #f2f2f2 !important;

  .project-card-header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
    width: 100%;
    flex-direction: column;

    .project-card-event {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }

    .project-card-date {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
    }

    .project-card-header-names {
      height: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .project-card-header-content {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);

      p {
        margin: 0px;
      }
    }

    .project-card-menu {
      position: absolute;
      top: -10px;
      right: -8px;

      .project-card-menu-button {
        padding: 0;
        min-width: 40px;
        min-height: inherit;
        max-height: 25px;
        border-radius: 0px;
      }
    }
  }

  .project-card-bottom {
    display: flex;
    justify-content: center;
  }

  .pointer {
    cursor: pointer;
  }

  .info-header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px;
  }

  .info-content {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .status {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #02a05c;
  }
  .status-inactive {
    color: rgba(0, 0, 0, 0.87);
  }

  .notes {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }
}
