.openJobCard {
  height: 100%;
  border: 1px solid #dedede;
  box-shadow: 0px 8px 18px rgba(104, 87, 87, 0.12) !important;
  overflow: initial !important;
  border-radius: 4px;

  .openJobCard-about {
    .openJobCard-about-title {
      text-transform: uppercase;
      font-size: 11px;
      line-height: 30px;
      margin-bottom: 0px;
      color: rgba(0, 0, 0, 0.54);
      font-weight: normal;
    }

    .openJobCard-about-event {
      font-size: 25px;
      line-height: 30px;
      font-weight: 400;
      color: #000000;
    }

    .openJobCard-about-date {
      color: rgba(0, 0, 0, 0.87);
      letter-spacing: 0.25px;
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;
    }

    .openJobCard-about-tentative {
      margin-right: 5px;
      height: 22px;
      width: 76px;
      border-radius: 99px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.25px;
      }
    }

    .openJobCard-about-description {
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.25px;
      margin-bottom: 0px;
    }
  }

  .openJobCard-jobDetails {
    .openJobCard-jobDetails-title {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: normal;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.54);
      margin-bottom: 0px;
    }

    .openJobsDetailCard {
      border: 1px solid #dedede;
    }
  }
}
