.session-feedback-summary {
  display: flex;
  justify-content: center;
  align-items: center;

  .neutral-summary {
    margin: 0 2.75vw;

    @media screen and (min-width: 480px) {
      margin: 0 40px;
    }
  }

  .summary {
    display: flex;

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }

    svg {
      margin-right: 25px;

      @media screen and (max-width: 480px) {
        margin-right: 10px;
        max-width: 12vw;
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .count {
        font-size: 32px;
        font-weight: 400;

        @media screen and (max-width: 480px) {
          font-size: 20px;
        }
      }

      .percentage {
        font-size: 18px;

        @media screen and (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
  }
}
